import axios from 'axios';
import { API_URL, DEFAULT_LOCALE } from '../common/helpers';

export default class Api {
  /* A function that is called when a new instance of the class is created. */
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = API_URL;
    this.locale = DEFAULT_LOCALE;
  }

  /**
   * Builds hash utility
   * @returns
   */
  static build() {
    return new this();
  }

  /* A function that is called when a new instance of the class is created. */
  init = () => {
    this.api_token = JSON.parse(localStorage.getItem('state'))?.user?.token;
    this.locale = localStorage.getItem('locale');

    let headers = {
      Accept: 'application/json',
    };

    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
      withCredentials: true,
    });

    this.client.defaults.params = { lang: this.locale };

    return this.client;
  };

  // auth
  signin = (params) => this.init().post(`auth/signin`, params);

  // app
  getApp = () => this.init().get('/app');

  // pages
  getDashboard = () => this.init().get('/dashboard');

  getContact = (id) => this.init().get(`/contacts/edit/${id}`);
  getContacts = (params) => this.init().get('contacts', { params: { ...params } });
  updateContact = (id, params) => this.post().get(`/contacts/update/${id}`, params);
  destroyContact = (id) => this.init().post(`/contacts/destroy/${id}`);

  // products
  getProduct = (id) => this.init().get(`/products/edit/${id}`);
  getProducts = (params) => this.init().get('/products', { params: { ...params } });
  createProduct = () => this.init().post(`/products/create`);
  updateProduct = (id, params) => this.init().post(`/products/update/${id}`, params);
  destroyProduct = (id) => this.init().post(`/products/destroy/${id}`);

  // categories
  getCategory = (id) => this.init().get(`/categories/edit/${id}`);
  getCategories = (params) => this.init().get('/categories', { params: { ...params } });
  createCategory = () => this.init().post(`/categories/create`);
  updateCategory = (id, params) => this.init().post(`/categories/update/${id}`, params);
  destroyCategory = (id) => this.init().post(`/categories/destroy/${id}`);

  // pages
  getPage = (id) => this.init().get(`/pages/edit/${id}`);
  getPages = (params) => this.init().get('/pages', { params: { ...params } });
  createPage = () => this.init().post(`/pages/create`);
  updatePage = (id, params) => this.init().post(`/pages/update/${id}`, params);
  destroyPage = (id) => this.init().post(`/pages/destroy/${id}`);

  // faqs
  getFaq = (id) => this.init().get(`/faqs/edit/${id}`);
  getFaqs = (params) => this.init().get('/faqs', { params: { ...params } });
  createFaq = () => this.init().post(`/faqs/create`);
  updateFaq = (id, params) => this.init().post(`/faqs/update/${id}`, params);
  destroyFaq = (id) => this.init().post(`/faqs/destroy/${id}`);

  // sliders
  getSlider = (id) => this.init().get(`/sliders/edit/${id}`);
  getSliders = (params) => this.init().get('/sliders', { params: { ...params } });
  createSlider = () => this.init().post(`/sliders/create`);
  updateSlider = (id, params) => this.init().post(`/sliders/update/${id}`, params);
  destroySlider = (id) => this.init().post(`/sliders/destroy/${id}`);

  // attributes
  getAttribute = (id) => this.init().get(`/attributes/edit/${id}`);
  getAttributes = (params) => this.init().get('/attributes', { params: { ...params } });
  createAttribute = () => this.init().post(`/attributes/create`);
  updateAttribute = (id, params) => this.init().post(`/attributes/update/${id}`, params);
  destroyAttribute = (id) => this.init().post(`/attributes/destroy/${id}`);

  // banners
  getBanner = (id) => this.init().get(`/banners/edit/${id}`);
  getBanners = (params) => this.init().get('/banners', { params: { ...params } });
  createBanner = () => this.init().post(`/banners/create`);
  updateBanner = (id, params) => this.init().post(`/banners/update/${id}`, params);
  destroyBanner = (id) => this.init().post(`/banners/destroy/${id}`);

  // orders
  getOrder = (id) => this.init().get(`/orders/edit/${id}`);
  getOrders = (params) => this.init().get('/orders', { params: { ...params } });
  createOrder = () => this.init().post(`/orders/create`);
  updateOrder = (id, params) => this.init().post(`/orders/update/${id}`, params);
  // destroyOrder = (id) => this.init().post(`/orders/destroy/${id}`);
  refundOrder = (id, params) => this.init().post(`/orders/refund/${id}`, params);

  // settings
  getSetting = () => this.init().get('settings/view');
  updateSetting = (params) => this.init().post(`/settings/update`, params);

  // users
  getUser = (id) => this.init().get(`/users/edit/${id}`);
  getUsers = (params) => this.init().get('/users', { params: { ...params } });
  createUser = () => this.init().post(`/users/create`);
  updateUser = (id, params) => this.init().post(`/users/update/${id}`, params);
  destroyUser = (id) => this.init().post(`/users/destroy/${id}`);

  // tools
  uploadImg = (img) => this.init().post('uploads/img', img);
  getEnums = () => this.init().get('tools/enums');
}
