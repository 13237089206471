import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Page, Card, Form, Grid, Loading, Thumbnail, FormLayout, InlineError, ContextualSaveBar, DropZone, TextField, Banner, Text, Stack, Select, OptionList, Scrollable } from '@shopify/polaris';
import { Content } from '../../../common';
import { useAttributeHook, useUploadHook } from '../../../hooks';
import { StatusBadgeHelper, cdn_img } from '../../../common/helpers';

export const AttributeView = () => {
  const { id } = useParams();
  const upload = useUploadHook();
  const { enums, error, success, loading, hasChanges, attribute, updateAttribute, attributeTrans, updateAttributeTrans, save, discard } = useAttributeHook(id);

  return (
    <Content>
      {(loading || upload.loading) && <Loading />}
      <Form onSubmit={save} encType={'multipart/form-data'}>
        <Page
          breadcrumbs={[{ content: 'Attributes', url: '/attributes' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                {attributeTrans.title}
              </Text>
              <Badge status={StatusBadgeHelper[attribute.status]}>{attribute.status}</Badge>
            </Stack>
          }
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card sectioned>
                <FormLayout>
                  <TextField required label="Title" onChange={(value) => updateAttributeTrans({ title: value })} value={attributeTrans.title} error={error?.errors?.title?.join(' ')} />
                  <TextField label="Description" onChange={(value) => updateAttributeTrans({ description: value })} value={attributeTrans.description} multiline={4} maxlength="320" helpText="Enter a full description." error={error?.errors?.description?.join(' ')} />
                  {/* <TextField required label="Url" onChange={(value) => updateAttributeTrans({ url: value })} value={attributeTrans.url} error={error?.errors?.url?.join(' ')} /> */}
                </FormLayout>
              </Card>
              {/* <Card title="Seo" sectioned>
                <FormLayout>
                  <TextField label="Meta description" onChange={(value) => updateAttributeTrans({ description_min: value })} value={attributeTrans.description_min} multiline={2} maxlength="160" error={error?.errors?.description_min?.join(' ')} />
                  <TextField label="Keywords" onChange={(value) => updateAttributeTrans({ keywords: value })} value={attributeTrans.keywords} error={error?.errors?.keywords?.join(' ')} />
                </FormLayout>
              </Card> */}
              {/* <Card title="Image" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updateAttribute({ img: await upload.img(e) });
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {attribute.img ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(attribute.img)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img} />
                </FormLayout>
              </Card> */}
              {/* <Card title="Mobile Image" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updateAttribute({ img_cover: await upload.img(e) });
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {attribute.img_cover ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(attribute.img_cover)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img_cover} />
                </FormLayout>
              </Card> */}
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              <Card title="Status" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.status?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateAttribute({ status: value })}
                    value={attribute.status}
                    error={error?.errors?.status?.join(' ')}
                  />
                </FormLayout>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>
      {hasChanges && (
        <ContextualSaveBar
          message="Save unsaved changes"
          saveAction={{
            onAction: () => save(attribute.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default AttributeView;
