export const StatusBadgeHelper = {
  Published: 'success',
  Draft: 'draft',
  Review: 'info',
  Sold: 'warning',
  Archived: 'critical',
};

export const ConditionBadgeHelper = {
  Active: 'success',
  Inactive: 'draft',
  Banned: 'warning',
  Archived: 'critical',
};

export const OrderStatusBadgeHelper = {
  Success: 'success',
  Draft: 'draft',
  Progress: 'info',
  Refunded: 'warning',
  Error: 'critical',
};
