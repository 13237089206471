import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchPage } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Api } from '../../services';

export const usePageHook = (id) => {
  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);
  const [pageTrans, setPageTrans] = useState([]);

  const updatePage = useCallback(
    (values) => {
      setPage((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [page],
  );

  const updatePageTrans = useCallback(
    (values) => {
      setPageTrans((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [pageTrans],
  );

  useEffect(() => {
    Api.build()
      .getPage(id)
      .then((response) => {
        const result = response.data;
        setPage(result.page);
        setPageTrans(result.page?.trans);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let params = { ...page, ...pageTrans };

    Api.build()
      .updatePage(id, params)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [page, pageTrans]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    loading,
    setLoading,
    hasChanges,
    setHasChanges,
    page,
    updatePage,
    pageTrans,
    updatePageTrans,
    save,
    discard,
  };
};

export default usePageHook;
