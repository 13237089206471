import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  new_count: 0,
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrdersCount: (state, { payload }) => {
      state.new_count = payload.count;
    },
    increaseOrdersCount: (state, { payload }) => {
      state.new_count = state.new_count - payload.count;
    },
  },
  extraReducers: {},
});

export const { setOrdersCount, increaseOrdersCount } = orderSlice.actions;

export default orderSlice.reducer;
