import { useParams } from 'react-router-dom';
import { Badge, Page, Card, Form, Grid, Loading, Icon, FormLayout, InlineError, ContextualSaveBar, DropZone, TextField, Banner, Text, Stack, Select, OptionList, Scrollable } from '@shopify/polaris';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ExternalMinor } from '@shopify/polaris-icons';
import { Content, TextEditor } from '../../../common';
import { useProductHook, useUploadHook } from '../../../hooks';
import { StatusBadgeHelper, WEB_URL, cdn_img } from '../../../common/helpers';

export const ProductView = () => {
  const { id } = useParams();
  const upload = useUploadHook();
  const { enums, error, success, data, loading, drag, setDrag, hasChanges, setHasChanges, product, productTrans, updateProduct, updateProductTrans, gallery, setGallery, selectedCategories, setSelectedCategory, categories, save, discard } = useProductHook(id);

  return (
    <Content bg={drag && '#ffcfcf'}>
      {(loading || upload.loading) && <Loading />}
      <Form style={{ background: 'red' }} onSubmit={save} encType={'multipart/form-data'}>
        <Page
          breadcrumbs={[{ content: 'Products', url: '/products' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                {productTrans.title}
              </Text>
              <Badge status={StatusBadgeHelper[product.status]}>{product.status}</Badge>
            </Stack>
          }
          subtitle={product.description_min}
          secondaryActions={[
            {
              content: (
                <>
                  View on your store
                  <Icon source={ExternalMinor} color={'primary'} />
                </>
              ),
              disabled: product.status != 'Published' ? true : false,
              onAction: () => window.open(WEB_URL + `/products/details/${product.id}`, '_blank'),
            },
          ]}
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card sectioned>
                <FormLayout>
                  <TextField required label="Title" onChange={(value) => updateProductTrans({ title: value })} value={productTrans.title} error={error?.errors?.title?.join(' ')} />
                  {!loading && <TextEditor onChange={(value) => updateProductTrans({ description: value })} value={productTrans.description} />}
                </FormLayout>
              </Card>
              <Card title={'Seo'} sectioned>
                <FormLayout>
                  <TextField label="Meta description" onChange={(value) => updateProductTrans({ description_min: value })} value={productTrans.description_min} multiline={2} maxlength="160" error={error?.errors?.description_min?.join(' ')} />
                  <TextField label="Keywords" onChange={(value) => updateProductTrans({ keywords: value })} value={productTrans.keywords} error={error?.errors?.keywords?.join(' ')} />
                </FormLayout>
              </Card>
              {/* 
              <Card title="Media" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updateProduct({ img: await upload.img(e) });
                      setHasChanges(true);
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {product.img ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(product.img)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img} />
                </FormLayout>
              </Card>
              <Card
                title={
                  <>
                    <Text variant="headingMd" as="h2">
                      Gallery
                    </Text>
                    <Text variant="bodySm" as="span" color="warning">
                      Drag image outside a box to remove or move between photos to sort.
                    </Text>
                  </>
                }
                sectioned
              >
                <Stack style={{ width: '100%' }} spacing={gallery?.length > 0 ? 'loose' : 'none'}>
                  <DragDropContext
                    onDragStart={() => setDrag(true)}
                    onDragEnd={(result) => {
                      const items = [...gallery];
                      // dropped outside the list
                      if (!result.destination) {
                        items.splice(result.source.index, 1);
                      } else {
                        const [removed_item] = items.splice(result.source.index, 1);
                        items.splice(result.destination.index, 0, removed_item);
                      }
                      setDrag(false);
                      setGallery(items);
                      setHasChanges(true);
                    }}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div className="gallery-draggable" style={{ display: gallery?.length > 0 ? 'grid' : 'none' }} {...provided.droppableProps} ref={provided.innerRef}>
                          {gallery?.map((item, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <img src={cdn_img(item.img)} className={'gallery-draggable-img'} />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <DropZone
                    onDrop={async (e) => {
                      setHasChanges(true);
                      setGallery([...gallery, ...(await upload.gallery(e))]);
                    }}
                    allowMultiple={true}
                    variableHeight
                  >
                    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
                  </DropZone>
                </Stack>
              </Card> */}

              <Card title="Price" sectioned>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField label="Price" onChange={(value) => updateProduct({ price: value })} value={product.price} prefix="$" error={error?.errors?.price?.join(' ')} />
                    <TextField label="Price Sale" onChange={(value) => updateProduct({ price_sale: value })} value={product.price_sale} prefix="$" helpText="Enter 0 If there is no discount!" error={error?.errors?.price_sale?.join(' ')} />
                  </FormLayout.Group>
                </FormLayout>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              <Card title="Status" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.status?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateProduct({ status: value })}
                    value={product.status}
                    error={error?.errors?.status?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title={'Stock'} sectioned>
                <FormLayout>
                  <TextField onChange={(value) => updateProduct({ stock: value })} value={product.stock} error={error?.errors?.stock?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Grade" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.grade?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateProduct({ grade: value })}
                    value={product.grade}
                    error={error?.errors?.grade?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Highlight" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.highlight?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateProduct({ highlight: value })}
                    value={product.highlight}
                    error={error?.errors?.highlight?.join(' ')}
                  />
                </FormLayout>
              </Card>
              {/* <Card title="Category" sectioned>
                <FormLayout>
                  <Scrollable
                    shadow
                    style={{
                      position: 'relative',
                      padding: '10px 0',
                      height: 250,
                    }}
                  >
                    {categories && (
                      <OptionList
                        onChange={(val) => {
                          setSelectedCategory(val);
                          setHasChanges(true);
                        }}
                        sections={categories?.map((item) => ({
                          title: item?.trans?.title,
                          options: [{ value: item.id, label: item?.trans?.title }, ...item?.children?.map((child) => ({ value: child.id, label: child.trans?.title }))],
                        }))}
                        selected={selectedCategories}
                        allowMultiple
                      />
                    )}
                  </Scrollable>
                </FormLayout>
              </Card> */}
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>
      {hasChanges && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: () => save(product.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default ProductView;
