import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { Api } from '../../services';

export const DashboardHook = () => {
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Api.build()
      .getDashboard()
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  return {
    data,
    error,
    loading,
  };
};

export default DashboardHook;
