import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexResourceState } from '@shopify/polaris';
import { Api } from '../../services';

export const OrdersHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [params, setParams] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  const { status } = useSelector((state) => state.app.data.enums.order);

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(orders?.data);

  const createOrder = () => {
    setLoading(true);
    Api.build()
      .createOrder()
      .then((response) => {
        navigate(`/orders/view/${response.data?.order?.id}`);
      })
      .catch((err) => console.log(err.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const loadOrders = () => {
    setLoading(true);
    Api.build()
      .getOrders({ page: page, ...params })
      .then((response) => {
        setOrders(response.data?.orders);
      })
      .catch((err) => console.log(err.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const destroyOrders = () => {
    setLoading(true);
    selectedResources.forEach((id) => {
      Api.build()
        .destroyOrder(id)
        .then((response) => {
          loadOrders();
          setSuccess(response.data?.message);
        })
        .catch((err) => console.log(err.response?.data?.message))
        .finally(() => setLoading(false));
    });
  };

  // {status: 'Published',}
  const updateOrders = (params) => {
    setLoading(true);
    selectedResources.forEach((id) => {
      Api.build()
        .updateOrder(id, params)
        .then((response) => {
          loadOrders();
          setSuccess(response.data?.message);
        })
        .catch((err) => console.log(err.response?.data?.message))
        .finally(() => setLoading(false));
    });
  };

  useEffect(() => {
    loadOrders();
  }, [page, params]);

  useEffect(() => {
    setTabs([
      {
        id: '0',
        content: 'All',
        children: <></>,
      },
      ...status?.map((item) => ({
        id: item,
        content: item,
        children: <></>,
      })),
    ]);
  }, [status]);

  // useEffect(() => {
  //   setParams((state) => ({
  //     ...state,
  //     ...filters.map((item) => ({ [item.type]: item.key })),
  //   }));
  // }, [filters]);

  const onTabChange = useCallback(
    (index) => {
      setSelectedTab(index);
      setParams((prev) => ({ ...prev, status: tabs[index]?.id }));
    },
    [tabs],
  );

  return {
    error,
    success,
    data,
    orders,
    tabs,
    page,
    setPage,
    loading,
    setLoading,
    params,
    setParams,
    filters,
    setFilters,
    createOrder,
    updateOrders,
    destroyOrders,
    selectedTab,
    setSelectedTab,
    onTabChange,
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  };
};

export default OrdersHook;
