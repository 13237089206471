import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexResourceState } from '@shopify/polaris';
import { Api } from '../../services';

export const ContactsHook = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    id: 0,
    open: false,
  });

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(contacts?.data);

  const updateModal = useCallback(
    (values) => {
      setModal((prevState) => ({ ...prevState, ...values }));
    },
    [modal],
  );

  const loadContacts = () => {
    setLoading(true);
    Api.build()
      .getContacts({ page: page, ...params })
      .then((response) => {
        setContacts(response.data?.contacts);
      })
      .catch((err) => console.log(err.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const destroyContacts = () => {
    setLoading(true);
    selectedResources.forEach((id) => {
      Api.build()
        .destroyContact(id)
        .then((response) => {
          loadContacts();
          setSuccess(response.data?.message);
        })
        .catch((err) => console.log(err.response?.data?.message))
        .finally(() => setLoading(false));
    });
  };

  // {status: 'Published',}
  const updateContacts = (params) => {
    setLoading(true);
    selectedResources.forEach((id) => {
      Api.build()
        .updateContact(id, params)
        .then((response) => {
          loadContacts();
          setSuccess(response.data?.message);
        })
        .catch((err) => console.log(err.response?.data?.message))
        .finally(() => setLoading(false));
    });
  };

  useEffect(() => {
    loadContacts();
  }, [page, params]);

  // useEffect(() => {
  //   setParams((state) => ({
  //     ...state,
  //     ...filters.map((item) => ({ [item.type]: item.key })),
  //   }));
  // }, [filters]);

  return {
    error,
    success,
    data,
    contacts,
    page,
    setPage,
    loading,
    setLoading,
    modal,
    updateModal,
    params,
    setParams,
    filters,
    setFilters,
    updateContacts,
    destroyContacts,
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  };
};

export default ContactsHook;
