import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchShippit } from 'react-router-dom';
import { Api } from '../../services';

export const useShippitHook = (order_id) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tracking, setTracking] = useState([]);

  const updateShippit = useCallback(
    (values) => {
      setData((prevState) => ({ ...prevState, ...values }));
    },
    [data],
  );

  const getShipping = useCallback(() => {
    Api.build()
      .getShippit(order_id)
      .then((response) => {
        const result = response.data;
        setData(result.shipping_order);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, [tracking]);

  const getTracking = useCallback(() => {
    Api.build()
      .trackShippit(order_id)
      .then((response) => {
        const result = response.data;
        setTracking(result.shipping_order?.data);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, [data]);

  useEffect(() => {
    getShipping();
    getTracking();
  }, []);

  const submit = useCallback(() => {
    setLoading(true);
    Api.build()
      .createShippit(order_id)
      .then((response) => {
        setTimeout(() => {
          getShipping();
          getTracking();
        }, 500);
        setError(null);
      })
      .catch((err) => {
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [data]);

  const destroy = useCallback(() => {
    setLoading(true);
    Api.build()
      .destroyShippit(order_id)
      .then((response) => {
        setTimeout(() => {
          getShipping();
          getTracking();
        }, 500);
        setError(null);
      })
      .catch((err) => {
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [data]);

  return {
    data,
    error,
    loading,
    setLoading,
    tracking,
    submit,
    destroy,
  };
};

export default useShippitHook;
