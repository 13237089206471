import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Page, Card, Form, Grid, Loading, Thumbnail, FormLayout, InlineError, ContextualSaveBar, DropZone, TextField, Banner, Text, Stack, Select, OptionList, Scrollable } from '@shopify/polaris';
import { Content, TextEditor } from '../../../common';
import { useFaqHook, useUploadHook } from '../../../hooks';
import { StatusBadgeHelper, cdn_img } from '../../../common/helpers';

export const FaqView = () => {
  const { id } = useParams();
  const upload = useUploadHook();
  const { enums, error, success, loading, hasChanges, faq, updateFaq, faqTrans, updateFaqTrans, save, discard } = useFaqHook(id);

  return (
    <Content>
      {(loading || upload.loading) && <Loading />}
      <Form onSubmit={save} encType={'multipart/form-data'}>
        <Page
          breadcrumbs={[{ content: 'Faqs', url: '/faqs' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                {faqTrans.title}
              </Text>
              <Badge status={StatusBadgeHelper[faq.status]}>{faq.status}</Badge>
            </Stack>
          }
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card sectioned>
                <FormLayout>
                  <TextField required label="Title" onChange={(value) => updateFaqTrans({ title: value })} value={faqTrans.title} error={error?.errors?.title?.join(' ')} />
                  {/* <TextField label="Description" onChange={(value) => updateFaqTrans({ description: value })} value={faqTrans.description} multiline={4} maxlength="320" helpText="Enter a full description." error={error?.errors?.description?.join(' ')} /> */}
                  {!loading && <TextEditor onChange={(value) => updateFaqTrans({ description: value })} value={faqTrans.description} />}
                </FormLayout>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              <Card title="Status" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.status?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateFaq({ status: value })}
                    value={faq.status}
                    error={error?.errors?.status?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title={'Sort'} sectioned>
                <FormLayout>
                  <TextField onChange={(value) => updateFaq({ sort: value })} value={faq.sort} error={error?.errors?.sort?.join(' ')} />
                </FormLayout>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>
      {hasChanges && (
        <ContextualSaveBar
          message="Save unsaved changes"
          saveAction={{
            onAction: () => save(faq.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default FaqView;
