import React, { useState, useCallback } from 'react'
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Toast } from '@shopify/polaris'
import { Api } from '../../services'
import { setToken, setInfo } from '../../redux/reducers/userSlice'

export const AuthHook = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const signin = () => {
    setLoading(true)

    Api.build()
      .signin({ email: email, password: password })
      .then((response) => {
        const response_data = response.data
        dispatch(setToken({ token: response_data?.token }))
        dispatch(setInfo({ info: response_data?.user }))
        navigate('/dashboard')
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false))
  }

  return {
    error,
    loading,
    setLoading,
    email,
    setEmail,
    password,
    setPassword,
    signin,
  }
}

export default AuthHook
