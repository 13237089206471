import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Layout, Card, Button, TextContainer, TextField, Text, Checkbox, Form, FormLayout } from '@shopify/polaris';
import { Logo } from '../../assets/images';
import { useAuthHook } from '../../hooks';

export const Login = () => {
  const { error, loading, setEmail, email, setPassword, password, signin } = useAuthHook();

  return (
    <>
      <Page fullWidth={false} narrowWidth={true}>
        <Layout>
          <Layout.Section>
            <div style={{ marginTop: 120 }}>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
                <img height="50" src={Logo} />
              </div>
              <Card
                title={
                  <div style={{ padding: 15, paddingBottom: 0 }}>
                    <TextContainer spacing="tight">
                      <Text variant="headingXl" as="h4">
                        Log in
                      </Text>
                      <Text variant="bodyMd" as="p" color="subdued">
                        Please enter your email and password to access admin account.
                      </Text>
                    </TextContainer>
                  </div>
                }
                sectioned
              >
                <div style={{ padding: 15 }}>
                  <Form onSubmit={signin}>
                    <FormLayout>
                      <TextField type="email" label="Email" onChange={setEmail} value={email} autoComplete="email" error={error} />
                      <TextField type="password" label="Password" onChange={setPassword} value={password} autoComplete="off" />
                      <Button primary fullWidth size="large" submit loading={loading}>
                        Log in
                      </Button>
                    </FormLayout>
                  </Form>
                </div>
              </Card>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default Login;
