import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Page, Card, Form, Grid, Loading, Thumbnail, FormLayout, InlineError, ContextualSaveBar, DropZone, TextField, Banner, Text, Stack, Select, OptionList, Scrollable } from '@shopify/polaris';
import { usePageHook, useUploadHook } from '../../../hooks';
import { StatusBadgeHelper, cdn_img } from '../../../common/helpers';
import { Content, TextEditor } from '../../../common';

export const PageView = () => {
  const { id } = useParams();
  const upload = useUploadHook();
  const { enums, error, success, data, loading, hasChanges, setHasChanges, page, updatePage, pageTrans, updatePageTrans, save, discard } = usePageHook(id);

  return (
    <Content>
      {(loading || upload.loading) && <Loading />}
      <Form onSubmit={save} encType={'multipart/form-data'}>
        <Page
          breadcrumbs={[{ content: 'Pages', url: '/pages' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                {pageTrans.title}
              </Text>
              <Badge status={StatusBadgeHelper[page.status]}>{page.status}</Badge>
            </Stack>
          }
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card sectioned>
                <FormLayout>
                  <TextField required label="Title" onChange={(value) => updatePageTrans({ title: value })} value={pageTrans.title} error={error?.errors?.title?.join(' ')} />
                  {/* <TextField label="Description" onChange={(value) => updatePageTrans({ description: value })} value={pageTrans.description} multiline={4} maxlength="320" helpText="Enter a full description." error={error?.errors?.description?.join(' ')} /> */}
                  {!loading && <TextEditor onChange={(value) => updatePageTrans({ description: value })} value={pageTrans.description} />}
                </FormLayout>
              </Card>
              <Card title="Seo" sectioned>
                <FormLayout>
                  <TextField label="Meta description" onChange={(value) => updatePageTrans({ description_min: value })} value={pageTrans.description_min} multiline={2} maxlength="160" error={error?.errors?.description_min?.join(' ')} />
                  <TextField label="Keywords" onChange={(value) => updatePageTrans({ keywords: value })} value={pageTrans.keywords} error={error?.errors?.keywords?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Media" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updatePage({ img: await upload.img(e) });
                      setHasChanges(true);
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {page.img ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(page.img)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img} />
                </FormLayout>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              <Card title="Status" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.status?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updatePage({ status: value })}
                    value={page.status}
                    error={error?.errors?.status?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title={'Sort'} sectioned>
                <FormLayout>
                  <TextField onChange={(value) => updatePage({ sort: value })} value={page.sort} error={error?.errors?.sort?.join(' ')} />
                </FormLayout>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>
      {hasChanges && (
        <ContextualSaveBar
          message="Save unsaved changes"
          saveAction={{
            onAction: () => save(page.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default PageView;
