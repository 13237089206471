import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Orders, OrderView, Products, ProductView, Pages, PageView, Users, UserView, Sliders, SliderView, Attributes, AttributeView, Banners, BannerView, Categories, CategoryView, Contacts, Dashboard, Login, SettingView, Faqs, FaqView } from '../pages';
import { getAppData } from '../redux/reducers/appSlice';

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAppData());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        {token ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contacts" element={<Contacts />} />

            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/view/:id" element={<OrderView />} />

            <Route path="/products" element={<Products />} />
            <Route path="/products/view/:id" element={<ProductView />} />

            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/view/:id" element={<CategoryView />} />

            <Route path="/pages" element={<Pages />} />
            <Route path="/pages/view/:id" element={<PageView />} />

            <Route path="/sliders" element={<Sliders />} />
            <Route path="/sliders/view/:id" element={<SliderView />} />

            <Route path="/attributes" element={<Attributes />} />
            <Route path="/attributes/view/:id" element={<AttributeView />} />

            <Route path="/banners" element={<Banners />} />
            <Route path="/banners/view/:id" element={<BannerView />} />

            <Route path="/users" element={<Users />} />
            <Route path="/users/view/:id" element={<UserView />} />

            <Route path="/faqs" element={<Faqs />} />
            <Route path="/faqs/view/:id" element={<FaqView />} />

            <Route path="/settings" element={<SettingView />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
