import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchProduct } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Api } from '../../services';

export const useProductHook = (id) => {
  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [drag, setDrag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);
  const [productTrans, setProductTrans] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [selectedCategories, setSelectedCategory] = useState([]);
  const [categories, setCategories] = useState([]);

  const updateProduct = useCallback(
    (values) => {
      setProduct((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [product],
  );

  const updateProductTrans = useCallback(
    (values) => {
      setProductTrans((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [productTrans],
  );

  useEffect(() => {
    Api.build()
      .getProduct(id)
      .then((response) => {
        const result = response.data;
        setGallery(result.product?.gallery);

        setProduct(result.product);
        setProductTrans(result.product?.trans);

        setCategories(result.categories);
        setSelectedCategory(result.product?.categories?.map((item) => item.id));
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let product_params = product;

    ['trans', 'categories', 'attributes', 'reviews', 'gallery'].forEach((e) => delete product_params[e]);

    let params = { ...product_params, ...productTrans, categories: selectedCategories, gallery: gallery };

    Api.build()
      .updateProduct(id, params)
      .then((response) => {
        setError(null);
        setHasChanges(false);
        setSuccess(response.data?.message);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [product, gallery, selectedCategories]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    loading,
    setLoading,
    drag,
    setDrag,
    hasChanges,
    setHasChanges,
    gallery,
    setGallery,
    product,
    updateProduct,
    productTrans,
    updateProductTrans,
    selectedCategories,
    setSelectedCategory,
    categories,
    save,
    discard,
  };
};

export default useProductHook;
