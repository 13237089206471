// export const API_URL = process.env.REACT_APP_ENV == 'local' ? process.env.REACT_APP_API_LOCAL : process.env.REACT_APP_ENV == 'development' ? process.env.REACT_APP_API_DEVELOPMENT : process.env.REACT_APP_API_PRODUCTION
// export const CDN_URL = process.env.REACT_APP_ENV == 'local' ? process.env.REACT_APP_CDN_LOCAL : process.env.REACT_APP_ENV == 'development' ? process.env.REACT_APP_CDN_DEVELOPMENT : process.env.REACT_APP_CDN_PRODUCTION
export const CURRENCY = '₾';
export const API_URL = 'https://app.happyshop.ge/api/admins/v1';
export const CDN_URL = 'https://app.happyshop.ge/uploads/images/';
export const WEB_URL = 'https://happyshop.ge';
export const DEFAULT_LOCALE = 'ka';
// export const API_URL = 'http://127.0.0.1:8000/admins/v1';
// export const CDN_URL = 'http://127.0.0.1:8000/uploads/images/';

export const cdn_img = (img) => CDN_URL + img;
