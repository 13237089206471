import { useState, useCallback, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Layout, Page, Card, Tabs, Thumbnail, Badge, DataTable, Button, Popover, ChoiceList, Icon, IndexTable, Text, Filters, ActionList, DatePicker, Stack, EmptyState } from '@shopify/polaris';
import { PlusMinor, DeleteMinor, EditMinor, NoteMajor, ArrowRightMinor } from '@shopify/polaris-icons';
import { Content } from '../../../common';
import { useOrdersHook } from '../../../hooks';
import { OrderStatusBadgeHelper } from '../../../common/helpers';

export const Orders = () => {
  const { data, orders, tabs, params, setParams, page, setPage, filters, setFilters, createOrder, updateOrders, destroyOrders, selectedTab, setSelectedTab, onTabChange, loading, setLoading, selectedResources, allResourcesSelected, handleSelectionChange } = useOrdersHook();

  return (
    <Content>
      <Page
        title="Orders"
        fullWidth={true}
        // primaryAction={{ content: 'Create Order', icon: PlusMinor, onAction: () => createOrder() }}
        // secondaryActions={[{ content: 'Export', disabled: true }]}
        pagination={{
          hasNext: orders?.current_order != orders?.last_order,
          hasPrevious: orders?.current_order != 1,
          onPrevious: () => {
            setPage(page - 1);
          },
          onNext: () => {
            setPage(page + 1);
          },
        }}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={selectedTab} onSelect={onTabChange}>
                <Card.Section>
                  <Filters filters={[]} queryValue={params?.search} appliedFilters={filters} onQueryChange={(e) => setParams((state) => ({ ...state, search: e }))} onQueryClear={(e) => setParams([])} onClearAll={() => setFilters([])} />
                </Card.Section>
                <Card.Section>
                  <IndexTable
                    resourceName={{
                      singular: 'Item',
                      plural: 'Items',
                    }}
                    itemCount={orders?.data?.length ?? 0}
                    selectedItemsCount={allResourcesSelected ? 'All' : selectedResources?.length}
                    onSelectionChange={handleSelectionChange}
                    // bulkActions={[
                    //   {
                    //     content: (
                    //       <>
                    //         <DeleteMinor width={18} /> Delete
                    //       </>
                    //     ),
                    //     onAction: () => destroyOrders(),
                    //   },
                    // ]}
                    promotedBulkActions={[
                      {
                        content: 'Set as success',
                        onAction: () => updateOrders({ status: 'Success' }),
                      },
                      {
                        content: 'Set as progress',
                        onAction: () => updateOrders({ status: 'Progress' }),
                      },
                    ]}
                    loading={loading}
                    emptyState={
                      <EmptyState
                        heading="Your data will show here"
                        // action={{ content: 'Add', onAction: () => createOrder() }}
                        // secondaryAction={{
                        //   content: 'Learn more',
                        //   onAction: () => createOrder(),
                        // }}
                        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                      >
                        <p>This page is empty.</p>
                      </EmptyState>
                    }
                    headings={[{ title: 'ID' }, { title: 'Customer' }, { title: 'Status' }, { title: 'Date' }, { title: 'Total' }, { title: 'Action' }]}
                  >
                    {orders?.data?.map((item, key) => (
                      <IndexTable.Row id={item.id} key={item.id} selected={selectedResources.includes(item.id)} position={key}>
                        <IndexTable.Cell>
                          <Stack>
                            <Text variant="bodyMd" fontWeight="bold" as="span">
                              #AA0{item.id}
                            </Text>
                            <Icon color="base" source={NoteMajor} />
                          </Stack>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {item.user?.name}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Badge status={OrderStatusBadgeHelper[item.status]}>{item.status}</Badge>
                        </IndexTable.Cell>
                        <IndexTable.Cell>{item.date}</IndexTable.Cell>
                        <IndexTable.Cell>{item.total} ₾</IndexTable.Cell>
                        <IndexTable.Cell>
                          <Link to={`/orders/view/${item.id}`}>
                            <Button size={'medium'} dataPrimaryLink>
                              View
                            </Button>
                          </Link>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    ))}
                  </IndexTable>
                </Card.Section>
              </Tabs>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Content>
  );
};

export default Orders;
