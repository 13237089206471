import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: null,
  token: null,
  loading: false,
  error: null,
  success: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    logout: (state) => {
      state.user_items = [];
    },

    setInfo: (state, { payload }) => {
      state.info = payload.info;
    },

    setToken: (state, { payload }) => {
      state.token = payload.token;
    },
  },
  extraReducers: {}
});

export const { logout, setInfo, setToken } = userSlice.actions;

export default userSlice.reducer;