export const ShippitOrderStatus = {
  processing: { title: 'Processing', description: 'the order is being moved from one tab to the next; this status can be an indicator that the order is stuck in between the two tabs' },
  order_placed: { title: 'Order Placed', description: 'the order is created in the Shippit New Orders tab; packaging size and courier assignment is yet to be confirmed' },
  despatch_in_progress: { title: 'Packing Order', description: 'the order is in the Shippit Ready to Ship tab; packaging size and courier assignment is already confirmed' },
  ready_for_pickup: { title: 'Booked for Delivery', description: 'the order is on the Shippit Track tab; order details are sent to the selected courier for pickup and the merchant is already charged for booking' },
  pickup_failed: { title: 'Pickup Failed', description: 'the order status for futile pickup or no-goods-to-go (NGTG); this means the courier driver went to pick up the package from the merchant but it is not ready yet' },
  invalidated: { title: '', description: 'only for priority/same-day delivery orders; an order that was booked but cannot be picked-up because of driver inavailability' },
  cancelled: { title: 'Cancelled', description: 'an order that was previously booked but has not been picked-up was cancelled from the Track tab' },
  in_transit: { title: 'In Transit', description: 'the order is already collected by the courier driver from the merchant; this can mean (a) the order is on its way to the depot or (b) is currently travelling in between depots' },
  untrackable: { title: 'Untrackable', description: 'the order was placed only to generate a plain label so no tracking events can be recorded; applicable for click-and-collect orders' },
  with_customs: { title: '', description: 'only for international orders; an order that has arrived at the destination country but is yet to cleared by customs' },
  customs_failed: { title: '', description: 'only for international orders; an order that has arrived at the destination country but was not cleared by customs' },
  customs_on_hold: { title: '', description: 'only for international orders; an order that has arrived at the destination country but is undergoing review by customs for any sort of reason not stated to Shippit by courier' },
  with_driver: { title: 'With Driver', description: "the order is onboard for delivery to the customer; this means it's delivery day and the item is already on the courier driver's van" },
  delivery_attempted: { title: 'Delivery Attempted', description: 'the courier driver attempted to deliver to the recipient address but no one is present to receive the order; a card is left at the delivery address so the recipient can arrange a redelivery.' },
  delivery_failed: { title: '', description: 'the courier driver attempted to deliver to the recipient address but the recipient no longer resides/works in the destination' },
  insufficient_address: {
    title: 'Insufficient Address',
    description: 'the courier driver cannot locate the destination based on the indicated order delivery address; this could mean the address is incorrect or insufficient; the merchant can validate the address in Google Maps and supply the correct/complete address',
  },
  awaiting_collection: { title: 'Awaiting Collection', description: 'the courier driver delivered the item to a collection point for the customer to collect; this means that redelivery can no longer be arranged' },
  await_collection: { title: '', description: 'same as above; used by legacy integrations' },
  completed: { title: 'Completed', description: 'the package is successfully delivered' },
  partially_completed: { title: '', description: 'a component of a multi-parcel order is delivered but other parcels within the same order are not yet delivered' },
  parcel_completed: { title: '', description: 'delivery of a multi-parcel order is completed' },
  lost: { title: 'Lost', description: 'the package is lost; if the order is covered by Shippit Transit Cover, a claim may be submitted' },
  damaged: { title: 'Damaged', description: 'the package is damaged; if the order is covered by Shippit Transit Cover, a claim may be submitted' },
  returned_to_sender: {
    title: 'Returned to Sender',
    description: 'the package was returned back to the merchant; this is possibly due to customer refusal to accept, customer failure to pick up from the designated collection point, redelivery attempts exceeded, incorrect address, or damaged package.',
  },
  return_requested: { title: 'Return Requested', description: 'the merchant booked a return of the order from the customer; this means the return label is now created' },
  awaiting_drop_off: { title: '', description: 'only for users of the Shippit Returns portal; a customer chooses to drop off at the courier office instead of booking the delivery for pick-up from his/her address' },
  return_booked: { title: '', description: 'only for users of the Shippit Returns portal; a customer books the delivery for pick-up from his/her address instead of choosing to drop off at the courier office' },
  return_booking_failed: { title: '', description: 'only for users of the Shippit Returns portal; a customer who booked the delivery for pick-up from his/her address but is unable to ship out the item' },
};
