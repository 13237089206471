import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchOrder } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setOrdersCount } from '../../redux/reducers/appSlice';
import { Api } from '../../services';

export const useOrderHook = (id) => {
  const dispatch = useDispatch();

  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState([]);

  const [refundAmount, setRefundAmount] = useState(0);
  const [refundModalOpen, setRefundModalOpen] = useState(false);

  const updateOrder = useCallback(
    (values) => {
      setOrder((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [order],
  );

  useEffect(() => {
    Api.build()
      .getOrder(id)
      .then((response) => {
        const result = response.data;
        setOrder(result.order);
        dispatch(setOrdersCount({ count: result.orders_new_count }));
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));

    // console.log(shippit);
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let params = order;

    Api.build()
      .updateOrder(id, params)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [order]);

  const refund = useCallback(() => {
    setLoading(true);

    Api.build()
      .refundOrder(id, refundAmount)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [order]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    refundModalOpen,
    setRefundModalOpen,
    refund,
    refundAmount,
    setRefundAmount,
    loading,
    setLoading,
    hasChanges,
    setHasChanges,
    order,
    updateOrder,
    save,
    discard,
  };
};

export default useOrderHook;
