import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TopBar, ActionList, Icon, Frame, Text, ButtonGroup, Button } from '@shopify/polaris';
import { ArrowLeftMinor, QuestionMarkMajor, SettingsMinor, LogOutMinor } from '@shopify/polaris-icons';
import { setDrawer } from '../../../redux/reducers/appSlice';
import { SetLocale, GetLocale } from '../../../services';

export const Menubar = () => {
  const dispatch = useDispatch();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const switchLang = () => {};

  return (
    <TopBar
      showNavigationToggle
      onNavigationToggle={() => dispatch(setDrawer({ visible: true }))}
      userMenu={
        <TopBar.UserMenu
          actions={[
            {
              items: [{ url: '/', content: 'Back to website', icon: ArrowLeftMinor }],
            },
            {
              items: [{ url: '/settings', content: 'Settings', icon: SettingsMinor }],
            },
            {
              items: [{ url: '/', content: 'Logout', icon: LogOutMinor }],
            },
          ]}
          name="Happy Shop"
          detail="Admin"
          initials="AL"
          open={isUserMenuOpen}
          onToggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
        />
      }
      secondaryMenu={
        <ButtonGroup segmented>
          <Button size={'slim'} pressed={GetLocale() == 'ka'} onClick={() => SetLocale('ka')}>
            GEO
          </Button>
          <Button size={'slim'} pressed={GetLocale() == 'en'} onClick={() => SetLocale('en')}>
            ENG
          </Button>
        </ButtonGroup>
      }
      // secondaryMenu={
      //   <TopBar.Menu
      //     activatorContent={
      //       <span>
      //         <Icon source={QuestionMarkMajor} />
      //         <Text variant="bodySm" as="span" visuallyHidden>
      //           Secondary menu
      //         </Text>
      //       </span>
      //     }
      //     open={isSecondaryMenuOpen}
      //     onOpen={() => setIsSecondaryMenuOpen(!isSecondaryMenuOpen)}
      //     onClose={() => setIsSecondaryMenuOpen(!isSecondaryMenuOpen)}
      //     actions={[
      //       {
      //         items: [{ content: 'Community forums' }],
      //       },
      //     ]}
      //   />
      // }
      searchResultsVisible={isSearchActive}
      searchField={
        <TopBar.SearchField
          onChange={(value) => {
            setSearchValue(value);
            setIsSearchActive(value.length > 0);
          }}
          value={searchValue}
          placeholder="Search"
          showFocusBorder
        />
      }
      searchResults={() => <></>}
      onSearchResultsDismiss={() => {
        setIsSearchActive(false);
        setSearchValue('');
      }}
    />
  );
};

export default Menubar;
