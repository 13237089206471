import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Frame, Loading, Text, Stack } from '@shopify/polaris';
import { Sidebar, Menubar } from '../../components';
import { setDrawer } from '../../../redux/reducers/appSlice';

export const Content = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { drawer } = useSelector((state) => state.app.utils);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <Frame
      topBar={<Menubar />}
      navigation={<Sidebar />}
      showMobileNavigation={drawer.visible}
      onNavigationDismiss={() => dispatch(setDrawer({ visible: false }))}
      logo={{
        width: 120,
        topBarSource: '/logo.svg',
        contextualSaveBarSource: '/logo.svg',
        url: '/dashboard',
      }}
      children={
        loading ? (
          <Loading />
        ) : (
          <>
            <div style={{ paddingBottom: 100, background: props.bg }}>{props.children}</div>
          </>
        )
      }
    />
  );
};

Content.defaultProps = {
  loading: false,
  bg: 'initial',
};

export default Content;
