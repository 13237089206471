import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Page, Card, CalloutCard, Badge, Banner, Grid, Icon, TextContainer, Text, Button, Stack } from '@shopify/polaris';
import { CustomersMinor, CircleRightMajor, ChevronUpMinor, SortDescendingMajor, ProductsMinor, OrdersMinor, DynamicSourceMinor } from '@shopify/polaris-icons';
import { useDashboardHook } from '../../hooks';
import { Content } from '../../common';
import { Locale } from '../../services';
import { StartStateIllustration } from '../../assets/images';

export const Dashboard = () => {
  const { data, laoding } = useDashboardHook();

  return (
    <Content>
      <Page title="" fullWidth={false}>
        <Layout>
          <Layout.Section>
            <div className="header-bg" style={{ backgroundImage: `url(${StartStateIllustration})`, padding: '100px 0' }}>
              <div>
                <TextContainer>
                  <Text variant="heading3xl" as="h1">
                    {Locale['Welcome to Happy Shop']}
                  </Text>
                  <p>Find orders, add new products, edit settings and many more.</p>
                  <Stack wrap={false}>
                    <Link to={'/orders'}>
                      <Button>Orders</Button>
                    </Link>
                    <Link to={'/products'}>
                      <Button>Products</Button>
                    </Link>
                    <Link to={'/settings'}>
                      <Button primary>Edit online shop settings</Button>
                    </Link>
                  </Stack>
                </TextContainer>
              </div>
            </div>
          </Layout.Section>
          {/* <Layout.Section>
            <Banner status="warning">Online store is in testing mode!</Banner>
          </Layout.Section> */}
          <Layout.Section>
            <Card title="Online store dashboard" sectioned>
              <TextContainer>
                <p>View a summary of your online store's performance.</p>
              </TextContainer>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Grid>
              <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 3 }}>
                <Card
                  title="Customers"
                  actions={[
                    {
                      content: <Icon source={CustomersMinor} color={'base'} />,
                    },
                  ]}
                  sectioned
                >
                  <Stack spacing={'extraTight'}>
                    <Stack spacing={'none'}>
                      <Icon source={SortDescendingMajor} color="success" />
                      <Text variant="heading2xl" as="h3" color="success">
                        {data.statistics?.users_last_month}
                      </Text>
                    </Stack>
                    <Text variant="Body" as="span">
                      last month
                    </Text>
                  </Stack>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 3 }}>
                <Card
                  title="Orders"
                  actions={[
                    {
                      content: <Icon source={OrdersMinor} color={'base'} />,
                    },
                  ]}
                  sectioned
                >
                  <Stack spacing={'extraTight'}>
                    <Stack spacing={'none'}>
                      <Icon source={SortDescendingMajor} color="success" />
                      <Text variant="heading2xl" as="h3" color="success">
                        {data.statistics?.orders_last_month}
                      </Text>
                    </Stack>
                    <Text variant="Body" as="span">
                      last 30 days
                    </Text>
                  </Stack>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 3 }}>
                <Card
                  title="Products"
                  actions={[
                    {
                      content: <Icon source={ProductsMinor} color={'base'} />,
                    },
                  ]}
                  sectioned
                >
                  <Stack spacing={'extraTight'}>
                    <Stack spacing={'none'}>
                      <Icon source={SortDescendingMajor} color="success" />
                      <Text variant="heading2xl" as="h3" color="success">
                        {data.statistics?.products_all}
                      </Text>
                    </Stack>
                    <Text variant="Body" as="span">
                      active products
                    </Text>
                  </Stack>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 3 }}>
                <Card
                  title="Categories"
                  actions={[
                    {
                      content: <Icon source={DynamicSourceMinor} color={'base'} />,
                    },
                  ]}
                  sectioned
                >
                  <Stack spacing={'extraTight'}>
                    <Stack spacing={'none'}>
                      <Icon source={SortDescendingMajor} color="success" />
                      <Text variant="heading2xl" as="h3" color="success">
                        {data.statistics?.categories_all}
                      </Text>
                    </Stack>
                    <Text variant="Body" as="span">
                      active categories
                    </Text>
                  </Stack>
                </Card>
              </Grid.Cell>
            </Grid>
          </Layout.Section>
          {/* <Layout.Section>
            <CalloutCard
              title="Customize the website"
              illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
              primaryAction={{
                content: 'Customize settings',
                url: '/settings',
              }}
            >
              <p>Edit your store data, customize seo, and more.</p>
            </CalloutCard>
          </Layout.Section> */}
        </Layout>
      </Page>
    </Content>
  );
};

export default Dashboard;
