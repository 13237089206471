import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Page, Card, Form, Grid, Loading, Thumbnail, Checkbox, FormLayout, InlineError, ContextualSaveBar, DropZone, TextField, Banner, Text, Stack, Select, OptionList, Scrollable } from '@shopify/polaris';
import { Content } from '../../../common';
import { useCategoryHook, useUploadHook } from '../../../hooks';
import { StatusBadgeHelper, cdn_img } from '../../../common/helpers';

export const CategoryView = () => {
  const { id } = useParams();
  const upload = useUploadHook();
  const { enums, error, success, data, loading, hasChanges, setHasChanges, category, updateCategory, categoryTrans, updateCategoryTrans, selectedCategories, setSelectedCategory, categories, save, discard } = useCategoryHook(id);

  return (
    <Content>
      {(loading || upload.loading) && <Loading />}
      <Form onSubmit={save} encType={'multipart/form-data'}>
        <Page
          breadcrumbs={[{ content: 'Categories', url: '/categories' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                {categoryTrans.title}
              </Text>
              <Badge status={StatusBadgeHelper[category.status]}>{category.status}</Badge>
            </Stack>
          }
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card sectioned>
                <FormLayout>
                  <TextField required label="Title" onChange={(value) => updateCategoryTrans({ title: value })} value={categoryTrans.title} error={error?.errors?.title?.join(' ')} />
                  <TextField label="Description" onChange={(value) => updateCategoryTrans({ description: value })} value={categoryTrans.description} multiline={4} maxlength="320" helpText="Enter a full description." error={error?.errors?.description?.join(' ')} />
                  {/* <TextField label="Small description" onChange={(value) => updateCategoryTrans({ description_min: value })} value={categoryTrans.description_min} multiline={2} maxlength="320" helpText="Enter a small description." error={error?.errors?.description_min?.join(' ')} /> */}
                </FormLayout>
              </Card>
              <Card title={'Seo'} sectioned>
                <FormLayout>
                  <TextField label="Meta description" onChange={(value) => updateCategoryTrans({ description_min: value })} value={categoryTrans.description_min} multiline={2} maxlength="160" error={error?.errors?.description_min?.join(' ')} />
                  <TextField label="Keywords" onChange={(value) => updateCategoryTrans({ keywords: value })} value={categoryTrans.keywords} error={error?.errors?.keywords?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Media" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updateCategory({ img: await upload.img(e) });
                      setHasChanges(true);
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {category.img ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(category.img)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img} />
                </FormLayout>
              </Card>
              <Card title="Media Cover" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updateCategory({ img_cover: await upload.img(e) });
                      setHasChanges(true);
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {category.img_cover ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(category.img_cover)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img_cover} />
                </FormLayout>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              <Card title="Status" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.status?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateCategory({ status: value })}
                    value={category.status}
                    error={error?.errors?.status?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Is Primary?" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.boolean?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateCategory({ primary: value })}
                    value={category.primary}
                    error={error?.errors?.primary?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title={'Sort'} sectioned>
                <FormLayout>
                  <TextField onChange={(value) => updateCategory({ sort: value })} value={category.sort} error={error?.errors?.sort?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Highlight" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.highlight?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateCategory({ highlight: value })}
                    value={category.highlight}
                    error={error?.errors?.highlight?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Parent category" sectioned>
                <Checkbox label="Without parent category" checked={category.parent_id == 0 ? true : false} onChange={() => updateCategory({ parent_id: 0 })} />
                <FormLayout>
                  <Scrollable
                    shadow
                    style={{
                      position: 'relative',
                      padding: '10px 0',
                      height: 250,
                    }}
                  >
                    {categories && (
                      <OptionList
                        // title={'Choose parent category'}
                        onChange={(value) => updateCategory({ parent_id: value[0] })}
                        sections={categories?.map((item) => ({
                          title: item.trans?.title,
                          options: [
                            // { value: '0', label: 'Without Parent' },
                            { value: item.id, label: item.trans?.title },
                            ...item?.children?.map((child) => ({
                              value: child.id,
                              label: child.trans?.title,
                            })),
                          ],
                        }))}
                        selected={[category.parent_id]}
                      />
                    )}
                  </Scrollable>
                </FormLayout>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>
      {hasChanges && (
        <ContextualSaveBar
          message="Save unsaved changes"
          saveAction={{
            onAction: () => save(category.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default CategoryView;
