export { default as Login } from './auth/Login';
export { default as Dashboard } from './dashboard/Dashboard';
export { default as Contacts } from './dashboard/contact/Contacts';
export { default as Orders } from './dashboard/order/Orders';
export { default as OrderView } from './dashboard/order/OrderView';
export { default as Products } from './dashboard/product/Products';
export { default as ProductView } from './dashboard/product/ProductView';
export { default as Pages } from './dashboard/page/Pages';
export { default as PageView } from './dashboard/page/PageView';
export { default as Sliders } from './dashboard/slider/Sliders';
export { default as SliderView } from './dashboard/slider/SliderView';
export { default as Attributes } from './dashboard/attribute/Attributes';
export { default as AttributeView } from './dashboard/attribute/AttributeView';
export { default as Banners } from './dashboard/banner/Banners';
export { default as BannerView } from './dashboard/banner/BannerView';
export { default as Users } from './dashboard/user/Users';
export { default as UserView } from './dashboard/user/UserView';
export { default as Categories } from './dashboard/category/Categories';
export { default as CategoryView } from './dashboard/category/CategoryView';
export { default as Faqs } from './dashboard/faq/Faqs';
export { default as FaqView } from './dashboard/faq/FaqView';
export { default as SettingView } from './dashboard/setting/SettingView';
