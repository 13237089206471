import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Badge, Page, Card, Form, Grid, Loading, Icon, Button, Thumbnail, FormLayout, IndexTable, ContextualSaveBar, DropZone, TextField, Banner, Text, Stack, Select, OptionList, Scrollable } from '@shopify/polaris';
import { NoteMajor } from '@shopify/polaris-icons';
import { Content } from '../../../common';
import { useUserHook } from '../../../hooks';
import { ConditionBadgeHelper, OrderStatusBadgeHelper } from '../../../common/helpers';

export const UserView = () => {
  const { id } = useParams();
  const { enums, error, success, data, loading, hasChanges, setHasChanges, user, updateUser, userTrans, updateUserTrans, save, discard } = useUserHook(id);

  return (
    <Content>
      {loading && <Loading />}
      <Form onSubmit={save} encType={'multipart/form-data'}>
        <Page
          breadcrumbs={[{ content: 'Users', url: '/users' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                {user.name}
              </Text>
              <Badge status={ConditionBadgeHelper[user.condition]}>{user.condition}</Badge>
            </Stack>
          }
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card sectioned>
                <FormLayout>
                  <TextField required label="Name" onChange={(value) => updateUser({ name: value })} value={user.name} error={error?.errors?.name?.join(' ')} />

                  <TextField required label="full_name" onChange={(value) => updateUserTrans({ full_name: value })} value={userTrans.full_name} error={error?.errors?.full_name?.join(' ')} />
                  <TextField required label="surname" onChange={(value) => updateUserTrans({ surname: value })} value={userTrans.surname} error={error?.errors?.surname?.join(' ')} />

                  <TextField required label="country" onChange={(value) => updateUserTrans({ country: value })} value={userTrans.country} error={error?.errors?.country?.join(' ')} />
                  <TextField required label="city" onChange={(value) => updateUserTrans({ city: value })} value={userTrans.city} error={error?.errors?.city?.join(' ')} />

                  <TextField required label="region" onChange={(value) => updateUserTrans({ region: value })} value={userTrans.region} error={error?.errors?.region?.join(' ')} />
                  <TextField required label="address" onChange={(value) => updateUserTrans({ address: value })} value={userTrans.address} error={error?.errors?.address?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Contact" sectioned>
                <FormLayout>
                  <TextField required disabled label="Phone" onChange={(value) => updateUser({ phone: value })} value={user.phone} error={error?.errors?.phone?.join(' ')} />
                  <TextField required label="Email" onChange={(value) => updateUser({ email: value })} value={user.email} error={error?.errors?.email?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Company" sectioned>
                <FormLayout>
                  <TextField required label="company_email" onChange={(value) => updateUser({ company_email: value })} value={user.company_email} error={error?.errors?.company_email?.join(' ')} />
                  <TextField required label="company_phone" onChange={(value) => updateUser({ company_phone: value })} value={user.company_phone} error={error?.errors?.company_phone?.join(' ')} />

                  <TextField required label="company_name" onChange={(value) => updateUserTrans({ company_name: value })} value={userTrans.company_name} error={error?.errors?.company_name?.join(' ')} />
                  <TextField required label="company_address" onChange={(value) => updateUserTrans({ company_address: value })} value={userTrans.company_address} error={error?.errors?.company_address?.join(' ')} />
                  <TextField required label="company_working_hours" onChange={(value) => updateUserTrans({ company_working_hours: value })} value={userTrans.company_working_hours} error={error?.errors?.company_working_hours?.join(' ')} />

                  <TextField required label="description" onChange={(value) => updateUserTrans({ description: value })} value={userTrans.description} error={error?.errors?.description?.join(' ')} />
                  <TextField required label="description_min" onChange={(value) => updateUserTrans({ description_min: value })} value={userTrans.description_min} error={error?.errors?.description_min?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Orders" sectioned>
                <IndexTable selectable={false} itemCount={user?.orders?.length ?? 0} loading={loading} headings={[{ title: 'ID' }, { title: 'Status' }, { title: 'Date' }, { title: 'Total' }]}>
                  {user?.orders?.map((item, key) => (
                    <IndexTable.Row id={item.id} key={item.id} position={key}>
                      <IndexTable.Cell>
                        <Stack>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            #AA0{item.id}
                          </Text>
                        </Stack>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Badge status={OrderStatusBadgeHelper[item.status]}>{item.status}</Badge>
                      </IndexTable.Cell>
                      <IndexTable.Cell>{item.date}</IndexTable.Cell>
                      <IndexTable.Cell>${item.total}</IndexTable.Cell>
                    </IndexTable.Row>
                  ))}
                </IndexTable>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              <Card title="Condition" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.user?.condition?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ condition: value })}
                    value={user.condition}
                    error={error?.errors?.condition?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Role" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.user?.role?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ role: value })}
                    value={user.role}
                    error={error?.errors?.role?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Type" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.user?.type?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ type: value })}
                    value={user.type}
                    error={error?.errors?.type?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Highlight" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.highlight?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ highlight: value })}
                    value={user.highlight}
                    error={error?.errors?.highlight?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Grade" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.grade?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ grade: value })}
                    value={user.grade}
                    error={error?.errors?.grade?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Delivery" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.boolean?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ delivery: value })}
                    value={user.delivery}
                    error={error?.errors?.delivery?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Payment" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.boolean?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ payment: value })}
                    value={user.payment}
                    error={error?.errors?.payment?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Verified" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.boolean?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ verified: value })}
                    value={user.verified}
                    error={error?.errors?.verified?.join(' ')}
                  />
                </FormLayout>
              </Card>
              <Card title="Has Happyshop fees?" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.boolean?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateUser({ has_fees: value })}
                    value={user.has_fees}
                    error={error?.errors?.has_fees?.join(' ')}
                  />
                </FormLayout>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>
      {hasChanges && (
        <ContextualSaveBar
          message="Save unsaved changes"
          saveAction={{
            onAction: () => save(user.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default UserView;
