import React from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Badge,
  Page,
  Card,
  Form,
  Grid,
  Loading,
  Button,
  ButtonGroup,
  InlineError,
  Layout,
  IndexTable,
  Thumbnail,
  DescriptionList,
  FormLayout,
  ResourceList,
  ResourceItem,
  ContextualSaveBar,
  DataTable,
  Avatar,
  TextField,
  Banner,
  Text,
  Stack,
  Select,
  OptionList,
  Modal,
  Link as DefaultLink,
  Icon,
  Spinner,
  SkeletonBodyText,
  SkeletonDisplayText,
  Divider,
} from '@shopify/polaris';
import { ExternalSmallMinor, ExternalMinor } from '@shopify/polaris-icons';
import { Content } from '../../../common';
import { useOrderHook, useUploadHook } from '../../../hooks';
import { OrderStatusBadgeHelper, cdn_img } from '../../../common/helpers';

export const OrderView = () => {
  const { id } = useParams();
  const upload = useUploadHook();
  const { enums, error, success, data, loading, refundModalOpen, setRefundModalOpen, refund, refundAmount, setRefundAmount, hasChanges, setHasChanges, order, updateOrder, save, discard } = useOrderHook(id);

  return (
    <Content>
      {(loading || upload.loading) && <Loading />}
      <Form onSubmit={save} encType={'multipart/form-data'}>
        <Page
          breadcrumbs={[{ content: 'Orders', url: '/orders' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                #AA0{order.id}
              </Text>
              <Text variant="bodyMd" as="span">
                Updated at {order.update_date}
              </Text>
              <Badge status={OrderStatusBadgeHelper[order.status]}>{order.status}</Badge>
            </Stack>
          }
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card title={'Products'} sectioned>
                <IndexTable itemCount={order?.order_data?.length ?? 0} loading={loading} headings={[{ title: 'Image' }, { title: 'Title' }, { title: 'Unit price' }, { title: 'Quantity' }, { title: 'Total Price' }]} selectable={false}>
                  {order?.order_data?.map((item, key) => (
                    <IndexTable.Row id={item.id} key={item.id} position={key}>
                      <IndexTable.Cell>
                        <Thumbnail size={'small'} source={cdn_img(item?.data?.img)} />
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Link to={`/products/view/${item?.target_id}`}>
                          <div style={{ whiteSpace: 'initial' }}>
                            <Text variant="bodyMd" fontWeight="bold" as="span" color="subdued">
                              {item?.data?.trans?.title}
                            </Text>
                          </div>
                        </Link>
                      </IndexTable.Cell>
                      <IndexTable.Cell>{item.unit_price} ₾</IndexTable.Cell>
                      <IndexTable.Cell>{item.qty}</IndexTable.Cell>
                      <IndexTable.Cell>{item.line_total} ₾</IndexTable.Cell>
                    </IndexTable.Row>
                  ))}
                </IndexTable>
              </Card>
              <Card title={'Payment'} sectioned>
                <Stack spacing="loose" vertical>
                  <DescriptionList
                    items={[
                      {
                        term: 'Subtotal',
                        description: order?.sub_total + ' ₾',
                      },
                      {
                        term: 'Shipping',
                        description: order?.delivery_cost + ' ₾',
                      },
                      {
                        term: 'Total',
                        description: (
                          <Text variant="bodyMd" fontWeight="bold" as="h3">
                            {order?.total} ₾
                          </Text>
                        ),
                      },
                    ]}
                  />
                  <Stack distribution="trailing">
                    <FormLayout>
                      <Select
                        options={enums?.order?.status?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        onChange={(value) => updateOrder({ status: value })}
                        value={order.status}
                        error={error?.errors?.status?.join(' ')}
                      />
                    </FormLayout>
                    <Button primary loading={loading} onClick={() => save(order.id)}>
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Card>
              <Card title={'თანხის დაბრუნება'} sectioned secondaryFooterActions={[{ content: 'თანხის დაბრუნება', onAction: () => setRefundModalOpen(true), disabled: order.status != 'Success', destructive: true }]}>
                <Text as="p">თქვენ გაქვთ შესაძლებლობა, მთლიანად ან ნაწილობრივ დააბრუნოთ შეკვეთის მოთხოვნის დროს გადახდილი თანხა.</Text>
                <Text as="p">შეკვეთის დასაბრუნებლად გთხოვთ მიჰყვეთ ინსტრუქციას.</Text>
                <Modal
                  title="თანხის დაბრუნება"
                  open={refundModalOpen}
                  // activator={<Button onClick={() => setRefundModalOpen(true)}>Open</Button>}
                  onClose={() => setRefundModalOpen(false)}
                  primaryAction={{
                    content: 'გაგრძელება',
                    loading: loading,
                    onAction: () => {
                      refund();
                      setTimeout(() => {
                        setRefundModalOpen(false);
                      }, 1000);
                    },
                  }}
                  secondaryActions={[
                    {
                      content: 'გაუქმება',
                      onAction: () => setRefundModalOpen(false),
                    },
                  ]}
                >
                  <Modal.Section>
                    <Stack spacing="loose" vertical>
                      <div>
                        <Text as="p">თქვენ გაქვთ შესაძლებლობა, მთლიანად ან ნაწილობრივ დააბრუნოთ შეკვეთის მოთხოვნის დროს გადახდილი თანხა.</Text>
                        <Text as="p">თანხის ნაწილობრივი დაბრუნების მეთოდის გამოძახება შესაძლებელია მანამ, სანამ გადახდილი თანხა არ იქნება სრულად დაბრუნებული.</Text>
                      </div>
                      <div>
                        <TextField
                          required
                          min={0}
                          type="number"
                          label="უკან დასაბრუნებელი თანხის ოდენობა"
                          onChange={(value) => setRefundAmount(value)}
                          value={refundAmount}
                          // error={}
                        />
                        <Text as="p" color="subdued">
                          გაითვალისწინეთ მინიმალური რაოდენობა არ უნდა იყოს 0 ზე ნაკლდები და მაქსიმალური რაოდენობა არ უნდა აღემატებოდეს {order.total} ₾
                        </Text>
                      </div>
                    </Stack>
                  </Modal.Section>
                </Modal>
              </Card>
              <Card title={'Other'} sectioned>
                <FormLayout>
                  <TextField label="Note" onChange={(value) => updateOrder({ description: value })} value={order.description} multiline={2} maxlength="320" helpText="Enter a full note." error={error?.errors?.description?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title={'Timeline'} sectioned subdued>
                <div class="timeline">
                  <div class="timeline-event">
                    <Stack vertical>
                      <Text variant="bodyMd" as="p" color="subdued" fontWeight="medium">
                        {order.update_date}
                      </Text>
                      <Text variant="bodyMd" as="p">
                        Order status updated to <Badge status={OrderStatusBadgeHelper[order.status]}>{order.status}</Badge>
                      </Text>
                    </Stack>
                  </div>
                  <div class="timeline-event">
                    <Stack vertical>
                      <Text variant="bodyMd" as="p" color="subdued" fontWeight="medium">
                        {order.date}
                      </Text>
                      <Text variant="bodyMd" as="p">
                        Payment redirected to checkout page.
                      </Text>
                    </Stack>
                  </div>
                  <div class="timeline-event">
                    <Stack vertical>
                      <Text variant="bodyMd" as="p" color="subdued" fontWeight="medium">
                        {order.date}
                      </Text>
                      <Text variant="bodyMd" as="p">
                        Order status updated to <Badge status={OrderStatusBadgeHelper['Progress']}>Progress</Badge>
                      </Text>
                    </Stack>
                  </div>
                  <div class="timeline-event">
                    <Stack vertical>
                      <Text variant="bodyMd" as="p" color="subdued" fontWeight="medium">
                        {order.date}
                      </Text>
                      <Text variant="bodyMd" as="p">
                        User{' '}
                        <Link to={`/users/view/${order?.user?.id}`}>
                          <Text variant="bodyMd" as="span" color={'success'} fontWeight={'medium'}>
                            {order?.user?.name}
                          </Text>
                        </Link>{' '}
                        create order #AA0{order.id}
                      </Text>
                    </Stack>
                  </div>
                </div>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              {/* <Card title="Status" sectioned>
                <FormLayout>
                  <Select
                    options={enums?.order?.status?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => updateOrder({ status: value })}
                    value={order.status}
                    error={error?.errors?.status?.join(' ')}
                  />
                </FormLayout>
              </Card> */}
              <Card title={'Buyer'} sectioned>
                <ResourceList
                  resourceName={{ singular: 'customer', plural: 'customers' }}
                  items={[
                    {
                      id: order?.user?.id,
                      url: `/users/view/${order?.user?.id}`,
                      name: order?.user?.name,
                      email: order?.user?.email,
                    },
                  ]}
                  renderItem={(item) => {
                    return (
                      <ResourceItem id={item?.id} url={item?.url} media={<Avatar customer size="medium" name={item?.name} />}>
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {item?.name}
                        </Text>
                        <div>#{item?.id}</div>
                      </ResourceItem>
                    );
                  }}
                />
                <Card.Section title="Contact information">
                  <Text>
                    <a href={`mailto:${order?.user?.email}`}>{order?.user?.email}</a>
                  </Text>
                  <Text>
                    <a href={`tel:${order?.user?.phone}`}>{order?.user?.phone}</a>
                  </Text>
                </Card.Section>
                <Card.Section title="Delivery address">
                  <Text>{order.delivery_address}</Text>
                </Card.Section>
              </Card>
              <Card title={'Seller'} sectioned>
                <ResourceList
                  resourceName={{ singular: 'customer', plural: 'customers' }}
                  items={[
                    {
                      id: order?.seller?.id,
                      url: `/users/view/${order?.seller?.id}`,
                      name: order?.seller?.name,
                      email: order?.seller?.email,
                    },
                  ]}
                  renderItem={(item) => {
                    return (
                      <ResourceItem id={item?.id} url={item?.url} media={<Avatar customer size="medium" name={item?.name} />}>
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {item?.name}
                        </Text>
                        <div>#{item?.id}</div>
                      </ResourceItem>
                    );
                  }}
                />
                <Card.Section title="Contact information">
                  <Text>
                    <a href={`mailto:${order?.seller?.email}`}>{order?.seller?.email}</a>
                  </Text>
                  <Text>
                    <a href={`tel:${order?.seller?.phone}`}>{order?.seller?.phone}</a>
                  </Text>
                </Card.Section>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>

      {hasChanges && (
        <ContextualSaveBar
          message="Save unsaved changes"
          saveAction={{
            onAction: () => save(order.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default OrderView;
