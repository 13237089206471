import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navigation, TopBar } from '@shopify/polaris';
import { HomeMinor, OrdersMinor, ProductsMinor, SettingsMinor, CirclePlusOutlineMinor, ConversationMinor, InfoMinor, NoteMinor, CustomersMinor, TemplateMinor, AppsMinor } from '@shopify/polaris-icons';

export const Sidebar = () => {
  const location = useLocation();
  const { orders } = useSelector((state) => state.app.data);

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: '/dashboard',
            label: 'Home',
            icon: HomeMinor,
          },
          {
            url: '/orders',
            label: 'Orders',
            icon: OrdersMinor,
            badge: orders?.new_count.toString(),
          },
          {
            url: '/products',
            label: 'Products',
            icon: ProductsMinor,
            subNavigationItems: [
              {
                url: '/categories',
                label: 'Categories',
              },
              {
                url: '/attributes',
                label: 'Attributes',
              },
            ],
          },
          {
            url: '/pages',
            label: 'Page',
            icon: NoteMinor,
          },
          {
            url: '/faqs',
            label: 'Faq',
            icon: InfoMinor,
          },
          {
            url: '/contacts',
            label: 'Contacts',
            icon: ConversationMinor,
          },
          {
            url: '/users',
            label: 'Customers',
            icon: CustomersMinor,
          },
        ]}
      />
      <Navigation.Section
        title="Personalization"
        items={[
          {
            url: '/sliders',
            label: 'Sliders',
            icon: TemplateMinor,
          },
          {
            url: '/banners',
            label: 'Banners',
            icon: AppsMinor,
          },
        ]}
      />
      <Navigation.Section
        title="Preferences"
        items={[
          {
            url: '/settings',
            label: 'Settings',
            icon: SettingsMinor,
          },
        ]}
      />
    </Navigation>
  );
};

export default Sidebar;
