import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Page, Card, Form, Grid, Loading, Thumbnail, FormLayout, InlineError, ContextualSaveBar, DropZone, TextField, Banner, Text, Stack, Select, OptionList, Scrollable } from '@shopify/polaris';
import { Content } from '../../../common';
import { useSettingHook, useUploadHook } from '../../../hooks';
import { StatusBadgeHelper, cdn_img } from '../../../common/helpers';

export const SettingView = () => {
  const { id } = useParams();
  const upload = useUploadHook();
  const { error, success, loading, hasChanges, setHasChanges, setting, updateSetting, settingTrans, updateSettingTrans, save, discard } = useSettingHook();

  return (
    <Content>
      {(loading || upload.loading) && <Loading />}
      <Form onSubmit={save} encType={'multipart/form-data'}>
        <Page
          // breadcrumbs={[{ content: 'Settings', url: '/settings' }]}
          title={
            <Stack>
              <Text variant="headingLg" as="h1">
                {settingTrans.title}
              </Text>
              {/* <Badge status={StatusBadgeHelper[setting.status]}>{setting.status}</Badge> */}
            </Stack>
          }
          fullWidth={false}
        >
          <Grid>
            {(error || success) && (
              <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {error && <Banner title={error.message} status={'critical'}></Banner>}
                {success && <Banner title={success} status={'success'}></Banner>}
              </Grid.Cell>
            )}
            <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 8, xl: 8 }}>
              <Card sectioned>
                <FormLayout>
                  <TextField required label="Title" onChange={(value) => updateSettingTrans({ title: value })} value={settingTrans.title} error={error?.errors?.title?.join(' ')} />
                  <TextField label="Description" onChange={(value) => updateSettingTrans({ description: value })} value={settingTrans.description} multiline={4} helpText="Enter a full description." error={error?.errors?.description?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Location" sectioned>
                <FormLayout>
                  <TextField label="Address" onChange={(value) => updateSettingTrans({ address: value })} value={settingTrans.address} multiline={2} error={error?.errors?.address?.join(' ')} />
                  <TextField label="Map" onChange={(value) => updateSetting({ map: value })} value={setting.map} multiline={2} error={error?.errors?.map?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Seo" sectioned>
                <FormLayout>
                  <TextField label="Meta description" onChange={(value) => updateSettingTrans({ description_min: value })} value={settingTrans.description_min} multiline={2} maxlength="160" error={error?.errors?.description_min?.join(' ')} />
                  <TextField label="Keywords" onChange={(value) => updateSettingTrans({ keywords: value })} value={settingTrans.keywords} error={error?.errors?.keywords?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Meta Image" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updateSetting({ img: await upload.img(e) });
                      setHasChanges(true);
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {setting.img ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(setting.img)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img} />
                </FormLayout>
              </Card>
              <Card title="Cover" sectioned>
                <FormLayout>
                  <DropZone
                    onDrop={async (e) => {
                      updateSetting({ img_cover: await upload.img(e) });
                      setHasChanges(true);
                    }}
                    allowMultiple={false}
                    variableHeight
                  >
                    {setting.img_cover ? <img style={{ width: 'auto', maxHeight: 300 }} src={cdn_img(setting.img_cover)} /> : <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />}
                  </DropZone>
                  <InlineError message={upload?.error?.img_cover} />
                </FormLayout>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}>
              <Card title="Contact" sectioned>
                <FormLayout>
                  <TextField required label="Phone" onChange={(value) => updateSetting({ phone: value })} value={setting.phone} error={error?.errors?.phone?.join(' ')} />
                  <TextField required label="Email" onChange={(value) => updateSetting({ email: value })} value={setting.email} error={error?.errors?.email?.join(' ')} />
                </FormLayout>
              </Card>
              <Card title="Social media" sectioned>
                <FormLayout>
                  <TextField label="Facebook" onChange={(value) => updateSetting({ facebook: value })} value={setting.facebook} error={error?.errors?.facebook?.join(' ')} />
                  <TextField label="instagram" onChange={(value) => updateSetting({ instagram: value })} value={setting.instagram} error={error?.errors?.instagram?.join(' ')} />
                  <TextField label="tiktok" onChange={(value) => updateSetting({ tiktok: value })} value={setting.tiktok} error={error?.errors?.tiktok?.join(' ')} />
                  <TextField required label="Youtube" onChange={(value) => updateSetting({ youtube: value })} value={setting.youtube} error={error?.errors?.youtube?.join(' ')} />
                  {/* <TextField required label="Linkedin" onChange={(value) => updateSetting({ linkedin: value })} value={setting.linkedin} error={error?.errors?.linkedin?.join(' ')} /> */}
                  {/* <TextField required label="Twitter" onChange={(value) => updateSetting({ twitter: value })} value={setting.twitter} error={error?.errors?.twitter?.join(' ')} /> */}
                </FormLayout>
              </Card>
            </Grid.Cell>
          </Grid>
        </Page>
      </Form>
      {hasChanges && (
        <ContextualSaveBar
          message="Save unsaved changes"
          saveAction={{
            onAction: () => save(setting.id),
            loading: loading,
            disabled: false,
          }}
          discardAction={{
            onAction: () => discard(),
          }}
        />
      )}
    </Content>
  );
};

export default SettingView;
