import LocalizedStrings from 'react-localization';
import { DEFAULT_LOCALE } from '../common/helpers';

import ka from '../lang/ka.json';
import en from '../lang/en.json';

export const Locale = new LocalizedStrings({
  en: {
    ...en,
  },
  ka: {
    ...ka,
  },
});

export const InitLocale = () => {
  if (localStorage.getItem('locale') == null) {
    localStorage.setItem('locale', DEFAULT_LOCALE);
  }

  Locale.setLanguage(localStorage.getItem('locale'));
};

export const GetLocale = () => {
  return localStorage.getItem('locale');
};

export const SetLocale = (lang) => {
  Locale.setLanguage(lang);
  localStorage.setItem('locale', lang);
  window.location.reload();
};
