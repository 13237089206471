import { useState, useCallback, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Layout, Page, Card, Tabs, Thumbnail, Badge, DataTable, Button, Popover, ChoiceList, IndexTable, Text, Filters, ActionList, DatePicker, EmptyState } from '@shopify/polaris';
import { PlusMinor, DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { Content } from '../../../common';
import { useProductsHook } from '../../../hooks';
import { StatusBadgeHelper, cdn_img } from '../../../common/helpers';
import { ViewMinor } from '@shopify/polaris-icons';

export const Products = () => {
  const { data, products, tabs, params, setParams, page, setPage, filters, setFilters, createProduct, updateProducts, destroyProducts, selectedTab, setSelectedTab, onTabChange, loading, setLoading, selectedResources, allResourcesSelected, handleSelectionChange, highlight } = useProductsHook();

  return (
    <Content>
      <Page
        title="Products"
        fullWidth={true}
        primaryAction={{ content: 'Create Product', icon: PlusMinor, onAction: () => createProduct() }}
        // secondaryActions={[{ content: 'Export', disabled: true }]}
        pagination={{
          hasNext: products?.current_page != products?.last_page,
          hasPrevious: products?.current_page != 1,
          onPrevious: () => {
            setPage(page - 1);
          },
          onNext: () => {
            setPage(page + 1);
          },
        }}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={selectedTab} onSelect={onTabChange}>
                <Card.Section>
                  <Filters
                    filters={
                      [
                        // {
                        //   key: 'Highlight',
                        //   label: 'Highlight',
                        //   filter: (
                        //     <ChoiceList
                        //       title="Highlight"
                        //       titleHidden
                        //       choices={highlight.map((item) => ({ label: item, value: item }))}
                        //       selected={filters?.map((item) => item.type == 'Highlight' && item.key)}
                        //       onChange={(e) =>
                        //         setFilters((state) => [
                        //           {
                        //             ...state,
                        //             ...{
                        //               type: 'Highlight',
                        //               key: e[0],
                        //               label: e[0],
                        //               onRemove: (e) => setFilters(filters?.filter((item) => item.type != 'Highlight')),
                        //             },
                        //           },
                        //         ])
                        //       }
                        //     />
                        //   ),
                        //   shortcut: false,
                        // },
                      ]
                    }
                    queryValue={params?.search}
                    appliedFilters={filters}
                    onQueryChange={(e) => setParams((state) => ({ ...state, search: e }))}
                    onQueryClear={(e) => setParams([])}
                    onClearAll={() => setFilters([])}
                  />
                </Card.Section>
                <Card.Section>
                  <IndexTable
                    resourceName={{
                      singular: 'Item',
                      plural: 'Items',
                    }}
                    itemCount={products?.data?.length ?? 0}
                    selectedItemsCount={allResourcesSelected ? 'All' : selectedResources?.length}
                    onSelectionChange={handleSelectionChange}
                    bulkActions={[
                      {
                        content: (
                          <>
                            <DeleteMinor width={18} /> Delete
                          </>
                        ),
                        onAction: () => destroyProducts(),
                      },
                    ]}
                    promotedBulkActions={[
                      {
                        content: 'Set as active',
                        onAction: () => updateProducts({ status: 'Published' }),
                      },
                      {
                        content: 'Set as draft',
                        onAction: () => updateProducts({ status: 'Draft' }),
                      },
                    ]}
                    loading={loading}
                    emptyState={
                      <EmptyState
                        heading="Your data will show here"
                        action={{ content: 'Add', onAction: () => createProduct() }}
                        secondaryAction={{
                          content: 'Learn more',
                          onAction: () => createProduct(),
                        }}
                        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                      >
                        <p>This page is empty.</p>
                      </EmptyState>
                    }
                    headings={[{ title: 'Image' }, { title: 'Title' }, { title: 'Status' }, { title: 'Date' }, { title: 'Price' }, { title: 'Sale Price' }, { title: 'Action' }]}
                  >
                    {products?.data?.map((item, key) => (
                      <IndexTable.Row id={item.id} key={item.id} selected={selectedResources.includes(item.id)} position={key}>
                        <IndexTable.Cell>
                          <Thumbnail source={cdn_img(item.img)} />
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Text variant="bodyMd" fontWeight="bold" as="span">
                            {item.trans?.title}
                          </Text>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          <Badge status={StatusBadgeHelper[item.status]}>{item.status}</Badge>
                        </IndexTable.Cell>
                        <IndexTable.Cell>{item.date}</IndexTable.Cell>
                        <IndexTable.Cell>{item.price}</IndexTable.Cell>
                        <IndexTable.Cell>{item.price_sale}</IndexTable.Cell>
                        <IndexTable.Cell>
                          <Link to={`/products/view/${item.id}`}>
                            <Button size={'medium'} dataPrimaryLink>
                              Edit
                            </Button>
                          </Link>
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    ))}
                  </IndexTable>
                </Card.Section>
              </Tabs>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Content>
  );
};

export default Products;
