import React, { Component, PropTypes } from 'react';
import RichTextEditor from 'react-rte';

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};
 
export default class TextEditor extends Component {
  state = {
    // value: RichTextEditor.createEmptyValue(),
    value: RichTextEditor.createValueFromString(this.props.value, 'html'),
  };
 
  // constructor() {
  //   super();
  //   this.setState({ value: RichTextEditor.createValueFromString(this.props?.value, 'html') });
  //   // this.state = {
  //   //   value: RichTextEditor.createValueFromString(this.props?.value, 'html'),
  //   // };
  //   // console.log(this.state.value);
  //   // console.log(this.props?.value);
  // }

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString('html'));
    }
    // console.log(value._cache);
  };

  // onLoad = (value) => {
  //   console.log('value')
  //   this.setState({ value });
  // };

  render() {
    return <RichTextEditor value={this.state.value} onChange={this.onChange} toolbarConfig={toolbarConfig} />;
  }
}
