import { useState, useCallback, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Layout, Page, Card, Tabs, Thumbnail, Badge, DataTable, Button, TextContainer, Tooltip, IndexTable, Text, Filters, Modal, DatePicker, EmptyState } from '@shopify/polaris';
import { PlusMinor, DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { Content } from '../../../common';
import { useContactsHook } from '../../../hooks';
import { StatusBadgeHelper, cdn_img } from '../../../common/helpers';
import { ViewMinor } from '@shopify/polaris-icons';

export const Contacts = () => {
  const { data, contacts, params, setParams, page, setPage, filters, setFilters, updateContacts, destroyContacts, modal, updateModal, loading, setLoading, selectedResources, allResourcesSelected, handleSelectionChange } = useContactsHook();

  return (
    <Content>
      <Page
        title="Contacts"
        fullWidth={true}
        // secondaryActions={[{ content: 'Export', disabled: true }]}
        pagination={{
          hasNext: contacts?.current_contact != contacts?.last_contact,
          hasPrevious: contacts?.current_contact != 1,
          onPrevious: () => {
            setPage(page - 1);
          },
          onNext: () => {
            setPage(page + 1);
          },
        }}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <Filters filters={[]} queryValue={params?.search} appliedFilters={filters} onQueryChange={(e) => setParams((state) => ({ ...state, search: e }))} onQueryClear={(e) => setParams([])} onClearAll={() => setFilters([])} />
              </Card.Section>
              <Card.Section>
                <IndexTable
                  resourceName={{
                    singular: 'Item',
                    plural: 'Items',
                  }}
                  itemCount={contacts?.data?.length ?? 0}
                  selectedItemsCount={allResourcesSelected ? 'All' : selectedResources?.length}
                  onSelectionChange={handleSelectionChange}
                  bulkActions={[
                    {
                      content: (
                        <>
                          <DeleteMinor width={18} /> Delete
                        </>
                      ),
                      onAction: () => destroyContacts(),
                    },
                  ]}
                  // promotedBulkActions={[
                  //   {
                  //     content: 'Set as active',
                  //     onAction: () => updateContacts({ status: 'Published' }),
                  //   },
                  //   {
                  //     content: 'Set as draft',
                  //     onAction: () => updateContacts({ status: 'Draft' }),
                  //   },
                  // ]}
                  loading={loading}
                  emptyState={
                    <EmptyState heading="Your data will show here" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
                      <p>This page is empty.</p>
                    </EmptyState>
                  }
                  headings={[{ title: 'Name' }, { title: 'Email' }, { title: 'Phone' }, { title: 'Date' }, { title: 'Action' }]}
                >
                  {contacts?.data?.map((item, key) => (
                    <IndexTable.Row id={item.id} key={item.id} selected={selectedResources.includes(item.id)} position={key}>
                      <IndexTable.Cell>
                        <Text variant="bodyMd" fontWeight="bold" as="span">
                          {item.name + ' ' + item.surname}
                        </Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>{item.email}</IndexTable.Cell>
                      <IndexTable.Cell>{item.phone}</IndexTable.Cell>
                      <IndexTable.Cell>{item.date}</IndexTable.Cell>
                      <IndexTable.Cell>
                        <Button
                          size={'medium'}
                          onClick={() =>
                            updateModal({
                              id: item.id,
                              open: true,
                            })
                          }
                          dataPrimaryLink
                        >
                          Message
                        </Button>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  ))}
                </IndexTable>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <Modal
          // activator={modal.open}
          open={modal.open}
          onClose={() =>
            updateModal({
              open: false,
            })
          }
          title="Message"
        >
          <Modal.Section>
            <TextContainer>
              <p>{modal.id != 0 && contacts?.data?.find((item) => item.id == modal.id).message}</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Page>
    </Content>
  );
};

export default Contacts;
