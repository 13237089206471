import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchUser } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Api } from '../../services';

export const useUserHook = (id) => {
  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [userTrans, setUserTrans] = useState([]);

  const updateUser = useCallback(
    (values) => {
      setUser((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [user],
  );

  const updateUserTrans = useCallback(
    (values) => {
      setUserTrans((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [userTrans],
  );

  useEffect(() => {
    Api.build()
      .getUser(id)
      .then((response) => {
        const result = response.data;
        setUser(result.user);
        setUserTrans(result.user?.trans);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let params = { ...user, ...userTrans };

    Api.build()
      .updateUser(id, params)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [user, userTrans]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    loading,
    setLoading,
    hasChanges,
    setHasChanges,
    user,
    updateUser,
    userTrans,
    updateUserTrans,
    save,
    discard,
  };
};

export default useUserHook;
