import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchBanner } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Api } from '../../services';

export const useBannerHook = (id) => {
  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [banner, setBanner] = useState([]);
  const [bannerTrans, setBannerTrans] = useState([]);

  const updateBanner = useCallback(
    (values) => {
      setBanner((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [banner],
  );

  const updateBannerTrans = useCallback(
    (values) => {
      setBannerTrans((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [bannerTrans],
  );

  useEffect(() => {
    Api.build()
      .getBanner(id)
      .then((response) => {
        const result = response.data;
        setBanner(result.banner);
        setBannerTrans(result.banner?.trans);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let params = { ...banner, ...bannerTrans };

    Api.build()
      .updateBanner(id, params)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [banner, bannerTrans]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    loading,
    hasChanges,
    setLoading,
    banner,
    updateBanner,
    bannerTrans,
    updateBannerTrans,
    save,
    discard,
  };
};

export default useBannerHook;
