import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchAttribute } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Api } from '../../services';

export const useAttributeHook = (id) => {
  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [attribute, setAttribute] = useState([]);
  const [attributeTrans, setAttributeTrans] = useState([]);

  const updateAttribute = useCallback(
    (values) => {
      setAttribute((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [attribute],
  );

  const updateAttributeTrans = useCallback(
    (values) => {
      setAttributeTrans((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [attributeTrans],
  );

  useEffect(() => {
    Api.build()
      .getAttribute(id)
      .then((response) => {
        const result = response.data;
        setAttribute(result.attribute);
        setAttributeTrans(result.attribute?.trans);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let params = { ...attribute, ...attributeTrans };

    Api.build()
      .updateAttribute(id, params)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [attribute, attributeTrans]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    loading,
    hasChanges,
    setLoading,
    attribute,
    updateAttribute,
    attributeTrans,
    updateAttributeTrans,
    save,
    discard,
  };
};

export default useAttributeHook;
