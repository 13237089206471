import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIndexResourceState } from '@shopify/polaris';
import { Api } from '../../services';

export const FaqsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [page, setPage] = useState(1);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [params, setParams] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  const { highlight } = useSelector((state) => state.app.data.enums);
  const { status } = useSelector((state) => state.app.data.enums);

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(faqs?.data);

  const createFaq = () => {
    setLoading(true);
    Api.build()
      .createFaq()
      .then((response) => {
        navigate(`/faqs/view/${response.data?.faq?.id}`);
      })
      .catch((err) => console.log(err.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const loadFaqs = () => {
    setLoading(true);
    Api.build()
      .getFaqs({ page: page, ...params })
      .then((response) => {
        setFaqs(response.data?.faqs);
      })
      .catch((err) => console.log(err.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const destroyFaqs = () => {
    setLoading(true);
    selectedResources.forEach((id) => {
      Api.build()
        .destroyFaq(id)
        .then((response) => {
          loadFaqs();
          setSuccess(response.data?.message);
        })
        .catch((err) => console.log(err.response?.data?.message))
        .finally(() => setLoading(false));
    });
  };

  // {status: 'Published',}
  const updateFaqs = (params) => {
    setLoading(true);
    selectedResources.forEach((id) => {
      Api.build()
        .updateFaq(id, params)
        .then((response) => {
          loadFaqs();
          setSuccess(response.data?.message);
        })
        .catch((err) => console.log(err.response?.data?.message))
        .finally(() => setLoading(false));
    });
  };

  useEffect(() => {
    loadFaqs();
  }, [page, params]);

  useEffect(() => {
    setTabs([
      {
        id: '0',
        content: 'All',
        children: <></>,
      },
      ...status?.map((item) => ({
        id: item,
        content: item,
        children: <></>,
      })),
    ]);
  }, [status]);

  // useEffect(() => {
  //   setParams((state) => ({
  //     ...state,
  //     ...filters.map((item) => ({ [item.type]: item.key })),
  //   }));
  // }, [filters]);

  const onTabChange = useCallback(
    (index) => {
      setSelectedTab(index);
      setParams((prev) => ({ ...prev, status: tabs[index]?.id }));
    },
    [tabs],
  );

  return {
    error,
    success,
    data,
    faqs,
    tabs,
    page,
    setPage,
    loading,
    setLoading,
    params,
    setParams,
    filters,
    setFilters,
    createFaq,
    updateFaqs,
    destroyFaqs,
    selectedTab,
    setSelectedTab,
    onTabChange,
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    highlight,
  };
};

export default FaqsHook;
