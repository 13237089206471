import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, createSearchFaq } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Api } from '../../services';

export const useFaqHook = (id) => {
  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [faq, setFaq] = useState([]);
  const [faqTrans, setFaqTrans] = useState([]);

  const updateFaq = useCallback(
    (values) => {
      setFaq((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [faq],
  );

  const updateFaqTrans = useCallback(
    (values) => {
      setFaqTrans((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [faqTrans],
  );

  useEffect(() => {
    Api.build()
      .getFaq(id)
      .then((response) => {
        const result = response.data;
        setFaq(result.faq);
        setFaqTrans(result.faq?.trans);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let params = { ...faq, ...faqTrans };

    Api.build()
      .updateFaq(id, params)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [faq, faqTrans]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    loading,
    hasChanges,
    setLoading,
    faq,
    updateFaq,
    faqTrans,
    updateFaqTrans,
    save,
    discard,
  };
};

export default useFaqHook;
