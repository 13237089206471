import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from '@shopify/polaris';
import { Api } from '../../services';

export const useCategoryHook = (id) => {
  const { enums } = useSelector((state) => state.app.data);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryTrans, setCategoryTrans] = useState([]);
  const [categories, setCategories] = useState([]);

  const updateCategory = useCallback(
    (values) => {
      setCategory((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [category],
  );

  const updateCategoryTrans = useCallback(
    (values) => {
      setCategoryTrans((prevState) => ({ ...prevState, ...values }));
      setHasChanges(true);
    },
    [categoryTrans],
  );

  useEffect(() => {
    Api.build()
      .getCategory(id)
      .then((response) => {
        const result = response.data;
        setCategory(result.category);
        setCategoryTrans(result.category?.trans);
        setCategories(result.categories);
      })
      .catch((err) => setError(err.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const save = useCallback(() => {
    setLoading(true);

    let params = { ...category, ...categoryTrans };

    Api.build()
      .updateCategory(id, params)
      .then((response) => {
        setError(null);
        setSuccess(response.data?.message);
        setHasChanges(false);
      })
      .catch((err) => {
        setSuccess(null);
        setError(err.response?.data);
      })
      .finally(() => setLoading(false));
  }, [category, categoryTrans]);

  const discard = () => {
    window.location.reload();
  };

  return {
    data,
    error,
    success,
    enums,
    loading,
    setLoading,
    hasChanges,
    setHasChanges,
    category,
    updateCategory,
    categoryTrans,
    updateCategoryTrans,
    categories,
    save,
    discard,
  };
};

export default useCategoryHook;
