import React, { useState, useCallback, useEffect } from 'react';
import { Api } from '../../services';

export const UploadHook = () => {
  const [error, setError] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const img = async (files, acceptedFiles, _rejectedFiles) => {
    setLoading(true);

    try {
      let formData = new FormData();
      formData.append('img', files[0]);

      let response = await Api.build().uploadImg(formData);
      let result = await response.data?.img;

      setLoading(false);

      return result;
    } catch (error) {
      setError({ img: [error.response?.data?.message] });
    }
  };

  const gallery = async (files, acceptedFiles, _rejectedFiles) => {
    setLoading(true);

    let formData = new FormData();

    let gallery = [];

    try {
      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);

        formData.append('img', files[i]);

        let response = await Api.build().uploadImg(formData);
        let result = await response.data;

        gallery.push({ img: result.img });
      }

      setLoading(false);

      return gallery;
    } catch (error) {
      setError({ gallery: [error.response?.data?.message] });
    }
  };

  return {
    error,
    data,
    loading,
    img,
    gallery,
  };
};

export default UploadHook;
